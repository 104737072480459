// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Intl & ThemeColors Context
import { ToastContainer } from "react-toastify";
import "@src/@core/components/ripple-button";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@src/@core/scss/react/libs/toastify/toastify.scss";
import "@src/@core/assets/fonts/feather/iconfont.css";
import "@src/@core/scss/core.scss";
import "@src/assets/scss/style.scss";
import "./index.scss";

import * as serviceWorker from "./serviceWorker";
import 'olympus-frontend/src/@core/scss/core.scss'
import 'react-tooltip/dist/react-tooltip.css'
import lazyWithRetry from 'olympus-frontend/src/components/lazyAppErrorRetry'
import { BrowserRouter } from "react-router-dom";



const LazyApp = lazyWithRetry(() => import("./App"));

ReactDOM.render(
    <BrowserRouter>
        <Suspense fallback={null}>
            <LazyApp/>
            <ToastContainer newestOnTop/>
        </Suspense>
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.unregister();
